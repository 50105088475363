import { PersistState } from '@kelvininc/types';
import {
	Context,
	PropsWithChildren,
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState
} from 'react';

import { NAVIGATION_EXPANDED_WIDTH } from '../config';
import { INavigationSideBarContextValues, INavigationSidebarState } from '../types';
import { getSideBarWidth } from '../utils';

export const NavigationSideBarContext: Context<null | INavigationSideBarContextValues> =
	createContext<null | INavigationSideBarContextValues>(null);

type NavigationSideBarProviderProps = {
	widthSetting: PersistState<INavigationSidebarState>;
	resetWidthSetting: () => void;
};

export function NavigationSideBarProvider({
	children,
	widthSetting: [widthState, setWidthState],
	resetWidthSetting
}: PropsWithChildren<NavigationSideBarProviderProps>) {
	const [isFixable, setIsFixable] = useState(true);
	const [isResizing, setIsResizing] = useState(false);

	const isCollapsed = useMemo(
		() => widthState.width < NAVIGATION_EXPANDED_WIDTH && !widthState.fixed,
		[widthState]
	);

	const resetToPreviousUserSettingState = useCallback(() => {
		setIsFixable(true);
		resetWidthSetting();
	}, [resetWidthSetting]);

	const collapseAndLockSidebar = useCallback(() => {
		setWidthState({ fixed: false, width: getSideBarWidth(false) }, false);
		setIsFixable(false);
	}, [setWidthState]);

	return (
		<NavigationSideBarContext.Provider
			value={{
				widthState: [widthState, setWidthState],
				resizingState: [isResizing, setIsResizing],
				isFixable,
				isCollapsed,
				resetToPreviousUserSettingState,
				collapseAndLockSidebar
			}}>
			{children}
		</NavigationSideBarContext.Provider>
	);
}

export function useNavigationSideBar(): INavigationSideBarContextValues {
	const context = useContext(NavigationSideBarContext);

	if (!context) {
		throw new Error('Missing navigation sidebar context');
	}

	return context;
}
