import { FunctionComponent, IExpandableSetting, PropsWithForwardedRef } from '@kelvininc/types';
import { isNil } from 'lodash-es';
import {
	Context,
	PropsWithChildren,
	createContext,
	forwardRef,
	useCallback,
	useContext,
	useImperativeHandle,
	useState
} from 'react';

import { RecoilState } from 'recoil';

import { useUserSetting } from '../../hooks';
import { Collapse } from '../Collapse/Collapse';

import {
	ExpandableProviderProps,
	IExpandableApi,
	IExpandableContextValues,
	PersistentExpandableProviderProps
} from './types';

const ExpandableContext: Context<null | IExpandableContextValues> =
	createContext<null | IExpandableContextValues>(null);

type ExpandableProps = {
	ExpandableAction?: JSX.Element;
	overflowOnExpanded?: boolean;
};

export const Expandable = ({
	ExpandableAction,
	overflowOnExpanded,
	forwardedRef,
	children
}: PropsWithForwardedRef<PropsWithChildren<ExpandableProps>, IExpandableApi>) => {
	const { isExpanded, open, toggle, close } = useExpandable();

	useImperativeHandle(forwardedRef, () => ({
		open,
		close,
		toggle
	}));

	return (
		<>
			{ExpandableAction}
			<Collapse isOpen={isExpanded} overflowOnExpanded={overflowOnExpanded}>
				{children}
			</Collapse>
		</>
	);
};

export function ExpandableProvider<T>({
	children,
	defaultState = true
}: ExpandableProviderProps<T>) {
	const [isExpanded, setExpanded] = useState(defaultState);
	const [isDefined, setIsDefined] = useState(false);

	const open = useCallback(() => setExpanded(true), []);
	const close = useCallback(() => setExpanded(false), []);
	const toggle = useCallback(() => {
		isExpanded ? close() : open();

		if (!isDefined) setIsDefined(true);
	}, [close, isExpanded, open, isDefined]);

	return (
		<ExpandableContext.Provider value={{ isExpanded, isDefined, toggle, open, close }}>
			{children}
		</ExpandableContext.Provider>
	);
}

export function PersistentExpandableProvider<T>({
	children,
	statePersistanceKey,
	recoilState,
	defaultState
}: PersistentExpandableProviderProps<T>) {
	const { value, setValue: setExpanded } = useUserSetting(
		statePersistanceKey,
		defaultState,
		recoilState
	);

	const open = useCallback(
		() => setExpanded({ isDefined: true, isExpanded: true }),
		[setExpanded]
	);
	const close = useCallback(
		() => setExpanded({ isDefined: true, isExpanded: false }),
		[setExpanded]
	);
	const toggle = () => (value.isExpanded ? close() : open());

	return (
		<ExpandableContext.Provider
			value={{
				isExpanded: value.isExpanded,
				isDefined: value.isDefined ?? false,
				toggle,
				open,
				close
			}}>
			{children}
		</ExpandableContext.Provider>
	);
}

export function useExpandable(): IExpandableContextValues {
	const context = useContext(ExpandableContext);

	if (!context) {
		throw new Error('Missing expandable context');
	}

	return context;
}

export const withExpandableProvider = <ComponentProps,>(
	Component: FunctionComponent<ComponentProps>,
	defaultState: IExpandableSetting,
	statePersistanceKey?: string,
	recoilState?: RecoilState<IExpandableSetting>
) => {
	return function ExpandableProviderWrapper(componentProps: PropsWithChildren<ComponentProps>) {
		if (isNil(statePersistanceKey) || isNil(recoilState)) {
			return (
				<ExpandableProvider defaultState={defaultState.isExpanded}>
					<Component {...componentProps} />
				</ExpandableProvider>
			);
		}

		return (
			<PersistentExpandableProvider
				statePersistanceKey={statePersistanceKey}
				recoilState={recoilState}
				defaultState={defaultState}>
				<Component {...componentProps} />
			</PersistentExpandableProvider>
		);
	};
};

Expandable.Forward = forwardRef<IExpandableApi, PropsWithChildren<ExpandableProps>>(
	function ExpandableWithRef({ children, ...otherProps }, ref) {
		return (
			<Expandable {...otherProps} forwardedRef={ref}>
				{children}
			</Expandable>
		);
	}
);
