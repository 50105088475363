import { IToaster } from '@kelvininc/react-ui-components';
import { ReactNode } from 'react';

export enum EToasterEvent {
	Open = 0,
	OpenChildren,
	Close
}

export type ToasterEventPayload =
	| ToasterOpenPayload
	| ToasterOpenWithChildrenPayload
	| ToasterClosePayload;

export type ToasterOpenPayload = {
	type: EToasterEvent.Open;
	payload: IToaster;
};

export type ToasterOpenWithChildrenPayload = {
	type: EToasterEvent.OpenChildren;
	payload: {
		config: IToaster;
		Children: ReactNode;
	};
};

export type ToasterClosePayload = {
	type: EToasterEvent.Close;
};
