import {
	App,
	Asset,
	DataStream,
	IUserKrnResource,
	IWorkloadAppVersionKrnResource,
	IWorkloadKrnResource,
	Workload as KvWorkload,
	RecommendationType,
	Unit,
	UserItem,
	UserMeGet
} from '@kelvininc/node-client-sdk';
import { IAlertConfig } from '@kelvininc/react-ui-components';
import { IClosedLoopSettings, ICoreAppPermissions } from '@kelvininc/types';

export enum ERecommendationConfidenceLevel {
	Disagree = 1,
	SoftAgree = 2,
	Agree = 3
}

export enum ERecommendationAction {
	Accept = 'accept',
	Reject = 'reject'
}

export enum ERecommendationModalAlertName {
	Error = 'error'
}

export enum ERecommendationSourceType {
	Workload = 'workload',
	Application = 'app',
	User = 'user'
}

export interface IRecommendationSourceDetails {
	label: string;
	value: string;
}

export interface IRecommendationModalAlert {
	key: ERecommendationModalAlertName;
	config?: Partial<IAlertConfig>;
}

export interface IRecommendationModalContent {
	recommendationId: string;
	metadata?: {
		users?: Record<string, UserItem>;
		user?: UserMeGet;
		recommendationTypes?: Record<string, RecommendationType>;
		assets?: Record<string, Asset>;
		datastreams?: Record<string, DataStream>;
		datastreamsUnits?: Record<string, Unit>;
		permissions?: ICoreAppPermissions;
	};
	closedLoopSettings: IClosedLoopSettings;
	timezoneOffset?: number;
	onAccept?: () => void;
	onReject?: () => void;
	onClose?: () => void;
}

export interface IRecommendationModalConfidences {
	isConfidencesOpen: boolean;
	showConfidences: () => void;
	closeConfidences: () => void;
}

export interface IRecommendationModalAlerts {
	alert: IRecommendationModalAlert | undefined;
	showErrorAlert: (error: string | Partial<IAlertConfig>) => void;
	closeAlerts: () => void;
}

export interface IRecommendationModalActions {
	onActionClick: (action: ERecommendationAction) => void;
	isPending: boolean;
}

export interface IRecommendationWorkloadSource {
	type: ERecommendationSourceType.Workload;
	model: KvWorkload;
	krn: IWorkloadKrnResource;
}

export interface IRecommendationApplicationSource {
	type: ERecommendationSourceType.Application;
	model: App;
	krn: IWorkloadAppVersionKrnResource;
}

export interface IRecommendationUserSource {
	type: ERecommendationSourceType.User;
	model: UserItem;
	krn: IUserKrnResource;
}

export type RecommendationSource =
	| IRecommendationWorkloadSource
	| IRecommendationApplicationSource
	| IRecommendationUserSource;
