import { useEffect, useState } from 'react';

import { MAX_PERCENTAGE_PROGRESS } from './config';

export const useProgressPercentage = () => {
	const [percentage, setPercentage] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setPercentage((previousPercentage) => {
				const newPercentage = Math.min(previousPercentage + 33, MAX_PERCENTAGE_PROGRESS);

				if (newPercentage === MAX_PERCENTAGE_PROGRESS && interval) {
					clearInterval(interval);
				}

				return newPercentage;
			});
		}, 100);

		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	});

	return percentage;
};
