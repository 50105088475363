import {
	EComponentSize,
	EIconName,
	EOtherIconName,
	ETooltipPosition
} from '@kelvininc/react-ui-components';
import { Dispatch, HTMLAttributeAnchorTarget, SetStateAction } from 'react';

export interface IDropdownItem {
	id: string;
	text: string;
	url?: string;
	onClick?: (item?: string) => void;
	target?: HTMLAttributeAnchorTarget;
	leftIcon?: EIconName | EOtherIconName;
	rightIcon?: EIconName | EOtherIconName;
	disabled?: boolean;
	tooltip?: string;
	tooltipPosition?: ETooltipPosition;
	itemSize?: EComponentSize;
	itemLevel?: number;
	prefetch?: boolean;
}

export interface IDropdownContextValues {
	isOpen: boolean;
	toggle: () => void;
	open: () => void;
	close: () => void;
	setOpen: Dispatch<SetStateAction<boolean>>;
}

export enum EDropdownSize {
	Small = 'small',
	Medium = 'medium',
	Large = 'large'
}
