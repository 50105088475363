import { debounce } from 'lodash-es';
import { RefObject, useCallback, useLayoutEffect, useMemo, useState } from 'react';

const RESIZE_DEBOUNCE_TIME = 350;

export interface IContentBox {
	height: number;
	width: number;
}

export const useResizeSensor = (
	elRef: RefObject<HTMLElement>,
	onResize: (box: IContentBox) => void,
	debounceTime = RESIZE_DEBOUNCE_TIME
) => {
	const [prevContentRect, setContentRect] = useState<IContentBox>();

	const onResizeHandler = useCallback(
		({ contentRect }: ResizeObserverEntry) => {
			const { width, height } = contentRect;

			// prevent same resize call
			if (width !== prevContentRect?.width || height !== prevContentRect?.height) {
				setContentRect({ width, height });
				onResize({ width, height });
			}
		},
		[onResize, setContentRect, prevContentRect]
	);

	const debounceResizeFn = useMemo(
		() => debounce(onResizeHandler, debounceTime),
		[debounceTime, onResizeHandler]
	);

	useLayoutEffect(() => {
		if (!elRef.current) return;

		const resizeObserver = new ResizeObserver(([ev]) => debounceResizeFn(ev));

		resizeObserver.observe(elRef.current);

		return () => {
			resizeObserver.disconnect();
		};
	}, [debounceResizeFn, elRef]);
};
