import { ReactNode } from 'react';

import { PanelManager } from './components';
import { PanelsContextProvider } from './contexts';

import styles from './styles.module.scss';

type PanelsManagerProps = {
	initialPanel: string;
	panels: Record<string, ReactNode>;
	width?: number;
};

export const PanelsManager = ({ initialPanel, panels, width }: PanelsManagerProps) => {
	return (
		<div className={styles.PanelsManager}>
			<PanelsContextProvider initialPanel={initialPanel} panels={Object.keys(panels)}>
				{Object.entries(panels).map(([id, PanelComponent]) => (
					<PanelManager key={id} id={id} width={width}>
						{PanelComponent}
					</PanelManager>
				))}
			</PanelsContextProvider>
		</div>
	);
};
