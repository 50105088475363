import { EIconName, ISelectSingleOption } from '@kelvininc/react-ui-components';

export const APPLICATION_CLOSED_LOOP_KEY = 'kelvin_closed_loop';

export const SCHEDULING_SPLIT_BUTTON_OPTIONS: ISelectSingleOption[] = [
	{
		label: 'Schedule',
		value: 'schedule',
		icon: EIconName.Schedule
	}
];
