import { ErrorBoundary } from '@sentry/nextjs';
import { ComponentProps, FunctionComponent, PropsWithChildren, Suspense } from 'react';

import { Boundaries, ErrorFallback, SuspenseFallback } from '../../components';

export const withErrorBoundary = <ComponentProps,>(
	Component: FunctionComponent<ComponentProps>,
	ErrorFallbackComponent = <ErrorFallback />
) => {
	return function ErrorBoundaryWrapper(
		componentProps: PropsWithChildren<ComponentProps>
	): JSX.Element {
		return (
			<ErrorBoundary fallback={ErrorFallbackComponent}>
				<Component {...componentProps} />
			</ErrorBoundary>
		);
	};
};

export const withSuspense = <ComponentProps extends {}>(
	Component: FunctionComponent<ComponentProps>,
	SuspenseFallbackComponent = <SuspenseFallback />
) => {
	return function SuspenseWrapper(
		componentProps: PropsWithChildren<ComponentProps>
	): JSX.Element {
		return (
			<Suspense fallback={SuspenseFallbackComponent}>
				<Component {...componentProps} />
			</Suspense>
		);
	};
};

export const withBoundaries = <Props,>(
	Component: FunctionComponent<Props>,
	props: ComponentProps<typeof Boundaries> = {}
) => {
	return function BoundariesWrapper(componentProps: PropsWithChildren<Props>): JSX.Element {
		return (
			<Boundaries {...props}>
				<Component {...componentProps} />
			</Boundaries>
		);
	};
};
