import { EComponentSize, KvAlert } from '@kelvininc/react-ui-components';
import { motion } from 'framer-motion';
import { ComponentProps } from 'react';

import { AlertActions } from '../../../../components';

import {
	ALERT_SLIDE_DOWN_ANIMATION_TRANSITION,
	ALERT_SLIDE_DOWN_ANIMATION_VARIANTS,
	DEFAULT_CANCEL_BUTTON_CONFIG
} from './config';
import styles from './styles.module.scss';

type PanelAlertProps = {
	alertConfig: ComponentProps<typeof KvAlert>;
	actionsConfig?: ComponentProps<typeof AlertActions>;
};

export const AnimatedPanelAlert = (props: PanelAlertProps) => {
	return (
		<motion.div
			initial="enter"
			animate="open"
			exit="exit"
			transition={ALERT_SLIDE_DOWN_ANIMATION_TRANSITION}
			variants={ALERT_SLIDE_DOWN_ANIMATION_VARIANTS}
			className={styles.AlertContainer}>
			<div className={styles.Alert}>
				<PanelAlert {...props} />
			</div>
		</motion.div>
	);
};

export const PanelAlert = ({ alertConfig, actionsConfig }: PanelAlertProps) => {
	return (
		<KvAlert size={EComponentSize.Small} showIcon={false} {...alertConfig}>
			{actionsConfig && (
				<div className={styles.Actions} slot="actions">
					<AlertActions
						size={EComponentSize.Small}
						cancelButtonConfig={DEFAULT_CANCEL_BUTTON_CONFIG}
						{...actionsConfig}
					/>
				</div>
			)}
		</KvAlert>
	);
};
