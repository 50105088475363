import { AtomEffect } from 'recoil';

import { IGetSettingConfig, getUserSetting } from '../../services';

export const syncUserSettingInitialValue: <T, M = T>(
	settingKey: string,
	defaultValue: T,
	config?: IGetSettingConfig<T, M>
) => AtomEffect<T> =
	(settingKey, defaultValue, config) =>
	({ setSelf }) =>
		setSelf(getUserSetting(settingKey, defaultValue, config));
