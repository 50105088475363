import { DependencyList, useEffect, useRef } from 'react';

/**
 * @deprecated - Don't use this hook, it's an anti-pattern.
 */
export const useMount = (callback: () => void) => {
	const didMountRef = useRef(false);

	useEffect(() => {
		if (didMountRef.current === false) {
			callback();
		}

		didMountRef.current = true;
		// this rule is disabled because we only want this effect
		// to run when the component is mounted
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};
/**
 * @deprecated - Don't use this hook, it's an anti-pattern.
 */
export const useUnmount = (callback: () => void) => {
	useEffect(() => {
		return () => {
			callback();
		};

		// this rule is disabled because we only want this effect
		// to run when the component is unmount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};
/**
 * @deprecated - Don't use this hook, it's an anti-pattern.
 */
export const useUpdate = (callback: () => void, deps: DependencyList = []) => {
	const didMountRef = useRef(false);

	useEffect(() => {
		if (didMountRef.current === true) {
			return callback();
		}

		// this rule is disabled because we only want this effect
		// to run when the component is update
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);

	useEffect(() => {
		didMountRef.current = true;

		return () => {
			didMountRef.current = false;
		};
	}, []);
};
