import { KelvinPermission } from '@kelvininc/types';
import { useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { hasPermissions } from './utils';

import { currentUserPermissionsMapSelector } from '@/src/recoil/users/selectors';

export const useHasPermissions = (
	requiredPermissions: KelvinPermission[],
	checkOperator = Array.prototype.every
) => {
	const permissions = useRecoilValue(currentUserPermissionsMapSelector);

	return useMemo(
		() => hasPermissions(permissions, requiredPermissions, checkOperator),
		[checkOperator, requiredPermissions, permissions]
	);
};
