import { CallbackInterface, useRecoilCallback } from 'recoil';

import { useInterval } from '../useInterval';

import { DEFAULT_POLLING_INTERVAL_IN_MS } from './config';

export const useRecoilAtomPolling = <Args extends ReadonlyArray<unknown>, Return = void>(
	args: Args,
	refreshCallback: (
		callback: CallbackInterface
	) => (...callbackArguments: Args) => Promise<Return>,
	pollingInterval: number = DEFAULT_POLLING_INTERVAL_IN_MS,
	running = true
): void => {
	const refresher = useRecoilCallback(refreshCallback, [...args, refreshCallback]);
	useInterval(() => refresher(...args), { delay: pollingInterval, running });
};
