import { KELVIN_PERMISSIONS, UserItem, UserMeGet } from '@kelvininc/node-client-sdk';
import { ICoreAppPermissions, KelvinPermission } from '@kelvininc/types';
import { keyBy } from 'lodash-es';
import { selector } from 'recoil';

import { getUserList, getUserMe } from './requests';

import { hasPermissions } from '@/src/hooks/useHasPermissions';

export const currentUserSelector = selector<UserMeGet>({
	key: 'currentUserSelector',
	get: () => getUserMe()
});

export const userListSelector = selector<UserItem[]>({
	key: 'userListSelector',
	get: () => getUserList()
});

export const usersMapSelector = selector<Record<string, UserItem>>({
	key: 'usersMapSelector',
	get: ({ get }) => keyBy(get(userListSelector), 'id')
});

export const currentUserPermissionsListSelector = selector<string[]>({
	key: 'currentUserPermissionsListSelector',
	get: ({ get }) => get(currentUserSelector).permissions
});

export const currentUserPermissionsMapSelector = selector<Record<KelvinPermission, boolean>>({
	key: 'currentUserPermissionsMapSelector',
	get: ({ get }) =>
		get(currentUserPermissionsListSelector).reduce<Record<KelvinPermission, boolean>>(
			(accumulator, value) => {
				accumulator[value as KelvinPermission] = true;

				return accumulator;
			},
			{} as Record<KelvinPermission, boolean>
		)
});

export const appUserPermissionsMapSelector = selector<ICoreAppPermissions>({
	key: 'appUserPermissionsMapSelector',
	get: ({ get }) => {
		const permissionsMap = get(currentUserPermissionsMapSelector);
		const hasPermissionsWithMapper = hasPermissions.bind(null, permissionsMap);

		const { Appmanager, ControlChange, Datatag, Parameter, Recommendation, Threads } =
			KELVIN_PERMISSIONS;
		const hasAppManagerUpdatePermission = hasPermissionsWithMapper([Appmanager.Update]);
		const hasAppManagerDeletePermission = hasPermissionsWithMapper([Appmanager.Delete]);
		const hasControlChangeCreatePermission = hasPermissionsWithMapper([ControlChange.Create]);
		const hasDataTagCreatePermission = hasPermissionsWithMapper([Datatag.Create]);
		const hasDataTagUpdatePermission = hasPermissionsWithMapper([Datatag.Update]);
		const hasParameterUpdatePermission = hasPermissionsWithMapper([Parameter.Update]);
		const hasParameterDeletePermission = hasPermissionsWithMapper([Parameter.Delete]);
		const hasRecommendationUpdatePermission = hasPermissionsWithMapper([Recommendation.Update]);
		const hasThreadsCreatePermission = hasPermissionsWithMapper([Threads.Create]);
		const hasThreadsUpdatePermission = hasPermissionsWithMapper([Threads.Update]);

		return {
			hasAppManagerUpdatePermission,
			hasAppManagerDeletePermission,
			hasControlChangeCreatePermission,
			hasDataTagCreatePermission,
			hasDataTagUpdatePermission,
			hasParameterUpdatePermission,
			hasParameterDeletePermission,
			hasRecommendationUpdatePermission,
			hasThreadsCreatePermission,
			hasThreadsUpdatePermission
		};
	}
});
