import { ECodeEditorTheme, KvCodeEditor } from '@kelvininc/react-ui-components';
import { type CodeEditor as MonacoApi } from '@kelvininc/react-ui-components';
import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { flushSync } from 'react-dom';

import { ECodeTranslatorEditorLanguage, ICodeTranslatorEditorProps } from './types';
import { parseCode, translateCode } from './utils';

export const CodeTranslatorEditor = ({
	object,
	language,
	readOnly,
	editorApiRef,
	monacoApiRef,
	onChange
}: ICodeTranslatorEditorProps) => {
	const [initialObject, setInitialObject] = useState<object>(object);
	const [code, setCode] = useState<string>();
	const prevLanguage = useRef<ECodeTranslatorEditorLanguage>();
	const localApiRef = useRef<MonacoApi>(null);

	const codeChange = useCallback(
		(value?: string) => {
			if (!value) return;

			try {
				const parsedObject = parseCode(value, language);
				const hasChanges = !isEqual(parsedObject, initialObject);

				onChange?.({
					code: value,
					valid: true,
					hasChanges,
					object: parsedObject,
					language
				});
			} catch {
				onChange?.({
					code: value,
					valid: false,
					hasChanges: true,
					object,
					language
				});
			}
		},
		[language, object, initialObject, onChange]
	);

	useImperativeHandle(monacoApiRef, () => localApiRef.current, []);
	useImperativeHandle(
		editorApiRef,
		() => ({
			resetValue: (resetObject: object) => {
				flushSync(() => {
					setInitialObject(resetObject);
				});

				localApiRef.current?.setValue(translateCode(resetObject, language));
			}
		}),
		[language]
	);

	useEffect(() => {
		if (prevLanguage.current === language) return;
		prevLanguage.current = language;
		const value = translateCode(object, language);
		setCode(value);
		codeChange(value);
	}, [object, language, codeChange]);

	return (
		<KvCodeEditor
			ref={localApiRef}
			code={code}
			language={language}
			customOptions={{ readOnly }}
			theme={ECodeEditorTheme.Custom}
			onChange={codeChange}
		/>
	);
};
