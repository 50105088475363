import { useCallback } from 'react';
import { RecoilState, useRecoilState } from 'recoil';

import { getUserSetting, removeUserSetting, setUserSetting } from '../../services';
import { IAsyncStoragePayload, useAsyncStorage } from '../useAsyncStorage';

import { IUserSettingStorageConfig } from './types';

export const useUserSetting = <T, M = T>(
	key: string,
	defaultValue: T,
	recoilState: RecoilState<T>,
	config: IUserSettingStorageConfig<T, M> = {}
): IAsyncStoragePayload<T> => {
	const [, setValue] = useRecoilState(recoilState);

	const resetValue = useCallback(async (): Promise<T> => {
		try {
			await removeUserSetting(key);
		} catch (error) {
			// ignore
		}

		setValue(defaultValue);
		return defaultValue;
	}, [defaultValue, key, setValue]);

	const storage = useAsyncStorage(key, defaultValue, recoilState, {
		...config,
		getStorageValue: getUserSetting,
		setStorageValue: setUserSetting
	});

	return {
		...storage,
		resetValue
	};
};
