import { debounce } from 'lodash-es';
import { SetStateAction, useMemo, useState } from 'react';

import { DEFAULT_DEBOUNCE_TIME_IN_MS } from './config';

export const useSearchState = (
	initialSearch?: string,
	timer = DEFAULT_DEBOUNCE_TIME_IN_MS
): [string | undefined, (newSearchTerm: SetStateAction<string | undefined>) => void] => {
	const [searchTerm, setSearchTerm] = useState<string | undefined>(initialSearch);

	const debouncedSetSearch = useMemo(() => {
		if (timer > 0) {
			return debounce(
				(newSearch: SetStateAction<string | undefined>) => setSearchTerm(newSearch),
				timer
			);
		}

		return setSearchTerm;
	}, [timer]);

	return [searchTerm, debouncedSetSearch];
};
