import { EIconName, KvIcon } from '@kelvininc/react-ui-components';
import { getFirstLetter } from '@kelvininc/tsutils';
import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';
import { useMemo } from 'react';

import styles from './styles.module.scss';
import { EAvatarForm, EAvatarSize, EAvatarType } from './types';

type AvatarProps = {
	name?: string;
	customClasses?: ClassNamesProp;
	size?: EAvatarSize;
	type?: EAvatarType;
	form?: EAvatarForm;
	system?: boolean;
};

export const Avatar = ({
	name = 'Kelvin',
	customClasses,
	size = EAvatarSize.Normal,
	type = EAvatarType.Primary,
	form = EAvatarForm.Rounded,
	system = false
}: AvatarProps) => {
	const letter = useMemo(() => getFirstLetter(name), [name]);

	return (
		<div
			className={classNames(
				styles.Avatar,
				styles[`Type${type}`],
				styles[`Size${size}`],
				styles[`Form${form}`],
				{
					[styles.SystemAvatar]: system
				},
				customClasses
			)}>
			{system ? (
				<KvIcon name={EIconName.LogoKelvin} />
			) : (
				<h4 className={styles.Letter}>{letter}</h4>
			)}
		</div>
	);
};
