import { ErrorFallback } from '../ErrorFallback';

import styles from './styles.module.scss';

export const WizardErrorFallback = () => {
	return (
		<div className={styles.WizardError}>
			<ErrorFallback fullscreen={false} />
		</div>
	);
};
