import { EIconName, KvIcon, KvTooltip } from '@kelvininc/react-ui-components';
import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';
import { AnimatePresence } from 'framer-motion';
import { isEmpty } from 'lodash-es';

import { PropsWithChildren } from 'react';

import { AnimatedPanelAlert } from './components/PanelAlert';
import styles from './styles.module.scss';

import { PanelAlertConfig } from './types';

export type PanelProps = {
	title?: string;
	description?: string;
	onRequestClose?: () => void;
	onBack?: () => void;
	tooltip?: string;
	customClasses?: ClassNamesProp;
	alert?: PanelAlertConfig;
};

export const Panel = ({
	title,
	description,
	onRequestClose,
	onBack,
	tooltip,
	customClasses,
	alert,
	children
}: PropsWithChildren<PanelProps>) => {
	return (
		<div className={classNames(styles.PanelWrapper, customClasses)}>
			<div className={styles.HeaderContent}>
				{!isEmpty(title) && (
					<div className={styles.Header}>
						<div className={styles.TitleContainer}>
							<span className={styles.Title}>{title}</span>
							{!isEmpty(tooltip) && (
								<span className={styles.Info}>
									<KvTooltip text={tooltip}>
										<KvIcon name={EIconName.Info} />
									</KvTooltip>
								</span>
							)}
						</div>
						{onRequestClose && (
							<span className={styles.Close} onClick={onRequestClose}>
								<KvIcon name={EIconName.Close} />
							</span>
						)}
					</div>
				)}
				<AnimatePresence initial>
					{alert && (
						<div>
							<AnimatedPanelAlert {...alert} />
						</div>
					)}
				</AnimatePresence>
				{onBack && (
					<div className={styles.Back} onClick={onBack}>
						<KvIcon name={EIconName.Back} />
						<span>Back</span>
					</div>
				)}
				{!isEmpty(description) && (
					<div className={styles.Description}>
						<span>{description}</span>
					</div>
				)}
			</div>
			{children}
		</div>
	);
};
