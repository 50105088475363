import { identity } from 'lodash-es';

import { getInstanceSetting } from './instance-settings';

import { IGetSettingConfig, ISetSettingConfig } from './types';
import { getUserSetting, removeUserSetting, setUserSetting } from './user-settings';

export const getSetting = async <T, M = T>(
	key: string,
	defaultValue: T,
	{ deserializer = identity, validator = () => true }: IGetSettingConfig<T, M> = {}
): Promise<T> => {
	try {
		try {
			const userSetting = await getUserSetting(key, null);
			if (userSetting !== null) {
				const setting = deserializer(userSetting);
				if (validator(setting)) {
					return setting;
				}
			}
		} catch (error) {
			console.warn(error);
		}

		const instanceSetting = await getInstanceSetting(key, null);
		if (instanceSetting !== null) {
			const setting = deserializer(instanceSetting);
			if (validator(setting)) {
				return setting;
			}
		}

		return defaultValue;
	} catch (error) {
		console.warn(error);
		return defaultValue;
	}
};

export const setSetting = async <T, M = T>(
	key: string,
	value: T,
	config: ISetSettingConfig<T, M> = {}
): Promise<void> => setUserSetting(key, value, config);

export const removeSetting = async (key: string): Promise<void> => removeUserSetting(key);
