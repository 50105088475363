import { EActionButtonType, KvActionButtonText } from '@kelvininc/react-ui-components';
import { Transition, Variants } from 'framer-motion';
import { ComponentProps } from 'react';

export const DEFAULT_CANCEL_BUTTON_CONFIG: Partial<ComponentProps<typeof KvActionButtonText>> = {
	type: EActionButtonType.Tertiary
};

export const ALERT_SLIDE_DOWN_ANIMATION_VARIANTS: Variants = {
	enter: { height: 0, transition: { duration: 0.3 } },
	open: { height: 'auto', transition: { duration: 0.3 } },
	exit: { height: 0, transition: { duration: 0.3 } }
};

export const ALERT_SLIDE_DOWN_ANIMATION_TRANSITION: Transition = { ease: 'easeInOut' };
