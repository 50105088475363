import styles from './styles.module.scss';

type SeeMoreLinkProps = {
	text?: string;
	onSeeMoreClicked: () => void;
};

export const SeeMoreLink = ({ text = 'See More', onSeeMoreClicked }: SeeMoreLinkProps) => {
	return (
		<div className={styles.SeeMore} onClick={onSeeMoreClicked}>
			{text}
		</div>
	);
};
