import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';
import { useRef } from 'react';

import { useClickInsideAlerter } from '../../hooks';

import styles from './styles.module.scss';

type OverlayProps = {
	open: boolean;
	customClasses?: ClassNamesProp;
	onClick?: () => void;
};

export const Overlay = ({ open, customClasses, onClick }: OverlayProps) => {
	const overlayRef = useRef<HTMLDivElement>(null);

	useClickInsideAlerter(overlayRef, () => open && onClick?.());

	if (!open) return null;

	return (
		<div
			className={classNames(styles.Overlay, customClasses, {
				[styles.Open]: open
			})}
			ref={overlayRef}
		/>
	);
};
