import { useModal } from '@kelvininc/react-ui-components';
import {
	Context,
	FunctionComponent,
	PropsWithChildren,
	ReactElement,
	createContext,
	useCallback,
	useContext,
	useState
} from 'react';

import {
	ERecommendationConfidenceLevel,
	IRecommendationModalAlert,
	IRecommendationModalContent
} from '../../../RecommendationModal/types';

import { IRecommendationModalContextValues } from './types';

import { assertContext } from './utils';

const RecommendationContext: Context<null | IRecommendationModalContextValues> =
	createContext<null | IRecommendationModalContextValues>(null);

export const RecommendationModalProvider = ({ children }: PropsWithChildren<{}>) => {
	const { open, close, isOpen } = useModal();

	const [content, setContent] = useState<IRecommendationModalContent>();
	const pendingState = useState(false);
	const confidencesOpenState = useState(false);
	const alertState = useState<IRecommendationModalAlert>();
	const confidenceLevelState = useState<ERecommendationConfidenceLevel>(
		ERecommendationConfidenceLevel.SoftAgree
	);

	const resetModal = useCallback(() => {
		const [, setConfidenceLevel] = confidenceLevelState;
		const [, setAlert] = alertState;
		const [, setConfidencesOpen] = confidencesOpenState;
		const [, setPending] = pendingState;
		setAlert(undefined);
		setConfidencesOpen(false);
		setPending(false);
		setConfidenceLevel(ERecommendationConfidenceLevel.SoftAgree);
	}, [alertState, confidenceLevelState, confidencesOpenState, pendingState]);

	const openModal = useCallback(
		(modalContent: IRecommendationModalContent) => {
			resetModal();
			setContent(modalContent);
			open();
		},
		[open, resetModal]
	);

	const closeModal = useCallback(() => {
		close();
		content?.onClose?.();
	}, [close, content]);

	return (
		<RecommendationContext.Provider
			value={{
				openModal,
				closeModal,
				isOpen,
				content,
				pendingState,
				alertState,
				confidenceLevelState,
				confidencesOpenState
			}}>
			{children}
		</RecommendationContext.Provider>
	);
};

export const RecommendationModalConsumer = ({
	children
}: {
	children: (context: IRecommendationModalContextValues) => ReactElement;
}) => {
	return (
		<RecommendationContext.Consumer>
			{(context) => {
				assertContext(context);

				return <>{children(context)}</>;
			}}
		</RecommendationContext.Consumer>
	);
};

export const useRecommendationModalContext = (): IRecommendationModalContextValues => {
	const context = useContext(RecommendationContext);

	assertContext(context);

	return context;
};

export const useRecommendationModalContent = (): IRecommendationModalContent => {
	const { content } = useRecommendationModalContext();

	if (!content) {
		throw new Error('Missing table recommendation modal content');
	}

	return content;
};

export const useRecommendationModalController = (): Pick<
	IRecommendationModalContextValues,
	'isOpen' | 'openModal' | 'closeModal'
> => {
	const { isOpen, openModal, closeModal } = useRecommendationModalContext();

	return { isOpen, openModal, closeModal };
};

export const withRecommendationModalProvider = <T,>(Component: FunctionComponent<T>) => {
	return function RecommendationModalProviderWrapper(componentProps: PropsWithChildren<T>) {
		return (
			<RecommendationModalProvider>
				<Component {...componentProps} />
			</RecommendationModalProvider>
		);
	};
};
