import { useCallback, useEffect, useRef, useState } from 'react';

import { DEFAULT_INTERVAL_IN_MS } from './config';
import { IUseInterval, IUseIntervalConfig } from './types';

export const useInterval = (
	callback: (() => void) | (() => Promise<void>),
	{ delay = DEFAULT_INTERVAL_IN_MS, running = true }: IUseIntervalConfig = {}
): IUseInterval => {
	const savedCallback = useRef<typeof callback>(callback);
	const [restartCounter, setRestartCounter] = useState<number>(0);
	const [isRunning, setRunning] = useState(running);

	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		let id: ReturnType<typeof setTimeout>;
		const executeCallback = async () => {
			if (!running || !isRunning) {
				return;
			}
			await savedCallback.current();
			scheduleCallback();
		};

		const scheduleCallback = () => {
			if (delay <= 0) {
				return;
			}

			id = setTimeout(executeCallback, delay);
		};

		scheduleCallback();

		return () => {
			clearTimeout(id);
		};
	}, [delay, running, restartCounter, isRunning]);

	const restart = useCallback(() => setRestartCounter((prev) => prev + 1), []);
	const stop = useCallback(() => setRunning(false), []);
	const start = useCallback(() => setRunning(true), []);

	return {
		restart,
		stop,
		start
	};
};
