import { KvActionButton, KvBadge } from '@kelvininc/react-ui-components';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';

import { ITab } from '../../types';

import styles from './styles.module.scss';

type TabProps<T extends string = string> = {
	id: T;
	onClick: (tab: T) => void;
	activeTab: T;
} & ITab<T>;

export const Tab = <T extends string = string>({
	id,
	title,
	badge,
	disabled,
	onClick,
	activeTab
}: TabProps<T>) => {
	const onClickTab = () => {
		if (!disabled) {
			onClick(id);
		}
	};
	const isActive = activeTab === id;
	const shouldShowBadge = !isEmpty(badge);

	return (
		<KvActionButton
			data-test-id="e2e-tabs-navigation-tab"
			onClickButton={onClickTab}
			disabled={disabled}
			className={classNames(styles.Tab, {
				[styles.TabActive]: isActive,
				[styles.TabDisabled]: disabled
			})}>
			<div className={styles.Title}>{title}</div>
			{shouldShowBadge && <KvBadge>{badge}</KvBadge>}
		</KvActionButton>
	);
};
