import { IToaster, ToasterContainer, useToaster } from '@kelvininc/react-ui-components';
import { ReactNode, useCallback, useEffect, useState } from 'react';

import { filter } from 'rxjs/operators';

import { toasterController$ } from './config';
import {
	EToasterEvent,
	ToasterClosePayload,
	ToasterOpenPayload,
	ToasterOpenWithChildrenPayload
} from './types';

export const ToasterObserver = () => {
	const { isOpen, config, openToaster, closeToaster } = useToaster();
	const [ChildrenContent, setChildrenContent] = useState<ReactNode>(null);

	const onClose = useCallback(() => {
		closeToaster();

		if (ChildrenContent) {
			setChildrenContent(null);
		}
	}, [ChildrenContent, closeToaster]);

	useEffect(() => {
		const subs = [
			toasterController$
				.pipe(
					filter((data): data is ToasterOpenPayload => data.type === EToasterEvent.Open)
				)
				.subscribe(({ payload }) => openToaster(payload)),

			toasterController$
				.pipe(
					filter(
						(data): data is ToasterOpenWithChildrenPayload =>
							data.type === EToasterEvent.OpenChildren
					)
				)
				.subscribe(({ payload }) => {
					setChildrenContent(payload.Children);
					openToaster(payload.config);
				}),

			toasterController$
				.pipe(
					filter((data): data is ToasterClosePayload => data.type === EToasterEvent.Close)
				)
				.subscribe(() => onClose())
		];

		return () => {
			subs.forEach((s) => s?.unsubscribe());
		};
	}, [closeToaster, onClose, openToaster]);

	return (
		<ToasterContainer {...config} isOpen={isOpen} onAfterClose={onClose}>
			{ChildrenContent}
		</ToasterContainer>
	);
};

export const openToaster = (toasterPayload: IToaster) => {
	toasterController$.next({
		type: EToasterEvent.Open,
		payload: toasterPayload
	});
};

export const openToasterWithChildren = (config: IToaster, Children: ReactNode) => {
	toasterController$.next({
		type: EToasterEvent.OpenChildren,
		payload: {
			config,
			Children
		}
	});
};

export const closeToaster = () => {
	toasterController$.next({
		type: EToasterEvent.Close
	});
};
