import { useCallback } from 'react';

import { usePanelsContext } from '../../contexts/PanelsContext';

import { UsePanelsManager } from './types';

export const usePanelsManager = (): UsePanelsManager => {
	const { history, setHistory } = usePanelsContext();

	const panel = history[history.length - 1];

	const push = useCallback(
		(newPanel: string): void => {
			if (newPanel === panel) {
				return;
			}

			setHistory((previous) => [...previous, newPanel]);
		},
		[panel, setHistory]
	);

	const back = useCallback((): void => {
		if (history.length < 2) {
			return;
		}

		const previousPanel = history[history.length - 2];

		setHistory((previous) => [...previous, previousPanel]);
	}, [history, setHistory]);

	return {
		panel,
		history,
		push,
		back
	};
};
