import { EIllustrationName, KvIllustration } from '@kelvininc/react-ui-components';
import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';

import styles from './styles.module.scss';

export type IllustrationMessageProps = {
	illustration?: EIllustrationName;
	title?: string;
	description?: string;
	customClasses?: ClassNamesProp;
};

export const IllustrationMessage = ({
	illustration,
	title,
	description,
	customClasses
}: IllustrationMessageProps) => {
	return (
		<div className={classNames(styles.IllustrationMessageContainer, customClasses)}>
			{illustration && <KvIllustration name={illustration} />}
			{title && <div className={styles.Title}>{title}</div>}
			{description && <div className={styles.Description}>{description}</div>}
		</div>
	);
};
