import { debounce } from 'lodash-es';
import { useMemo } from 'react';

import { DEFAULT_DEBOUCE_TIME_MS } from './config';

export const useDebounce: typeof debounce = (
	callbackFn,
	timer = DEFAULT_DEBOUCE_TIME_MS,
	options
) => {
	return useMemo(() => debounce(callbackFn, timer, options), [callbackFn, options, timer]);
};
