import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';

import { Skeleton } from '../Skeleton';

import { Tab } from './components';
import styles from './styles.module.scss';
import { ITab } from './types';

type TabsProps<T extends string = string> = {
	activeTab: T;
	tabs: ITab<T>[];
	actions?: JSX.Element;
	onClickTab: (tab: T) => void;
	customClasses?: ClassNamesProp;
};

type TabsSkeletonProps = {
	itemCount?: number;
	customClasses?: ClassNamesProp;
};

export const TabsSkeleton = ({
	children,
	customClasses,
	itemCount = 2
}: PropsWithChildren<TabsSkeletonProps>) => {
	return (
		<div className={classNames(styles.Tabs, customClasses)}>
			<div className={styles.Header}>
				<div className={styles.Navigation}>
					{Array(itemCount)
						.fill({})
						.map((_item, index) => (
							<Skeleton key={index} className={styles.Skeleton} />
						))}
				</div>
			</div>
			<div className={styles.Outlet}>{children}</div>
		</div>
	);
};

export const Tabs = <T extends string = string>({
	activeTab,
	tabs,
	actions,
	onClickTab,
	customClasses,
	children
}: PropsWithChildren<TabsProps<T>>) => {
	return (
		<div className={classNames(styles.Tabs, customClasses)}>
			<div className={styles.Header}>
				<div data-test-id="e2e-tabs-navigation" className={styles.Navigation}>
					{tabs.map((tab) => (
						<Tab
							{...tab}
							key={tab.key}
							id={tab.key}
							onClick={onClickTab}
							activeTab={activeTab}
						/>
					))}
				</div>
				{actions && <div className={styles.Actions}>{actions}</div>}
			</div>
			<div className={styles.Outlet}>{children}</div>
		</div>
	);
};
