import { jsonParser, yamlParser } from './languages/parsers';
import { jsonTranslator, yamlTranslator } from './languages/translators';
import { ECodeTranslatorEditorLanguage, ICodeLanguageTransformer } from './types';

export const CODE_TRANSFORMERS: Record<ECodeTranslatorEditorLanguage, ICodeLanguageTransformer> = {
	[ECodeTranslatorEditorLanguage.YAML]: {
		parse: yamlParser,
		translate: yamlTranslator
	},
	[ECodeTranslatorEditorLanguage.JSON]: {
		parse: jsonParser,
		translate: jsonTranslator
	}
};

export const NO_TRANSLATOR_FOUND_EXCEPTION =
	'No translator was found for the specified target language';
export const NO_PARSER_FOUND_EXCEPTION = 'No parser was found for the specified target language';
