import classNames from 'classnames';
import ReactLoadingSkeleton, { SkeletonProps } from 'react-loading-skeleton';

import styles from './styles.module.scss';

export const Skeleton = ({ containerClassName, ...props }: SkeletonProps) => (
	<ReactLoadingSkeleton
		containerClassName={classNames(styles.SkeletonContainer, containerClassName)}
		{...props}
	/>
);
