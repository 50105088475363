export enum EAvatarSize {
	Small = 'Small',
	Normal = 'Normal',
	Large = 'Large'
}

export enum EAvatarType {
	Primary = 'Primary',
	Secondary = 'Secondary'
}

export enum EAvatarForm {
	Rounded = 'Rounded',
	Squared = 'Squared'
}
