import { throttle } from 'lodash-es';
import { RefObject, useEffect, useMemo, useState } from 'react';

import { IScroll } from './types';

const getScrollTop = <T extends HTMLElement>(element: RefObject<T>): number => {
	if (element.current) {
		const { scrollTop } = element.current;

		return scrollTop;
	}

	return 0;
};

const getScrollBottom = <T extends HTMLElement>(element: RefObject<T>): number => {
	if (element.current) {
		const { scrollHeight, offsetHeight, scrollTop } = element.current;

		return scrollHeight - offsetHeight - scrollTop;
	}

	return 0;
};

const getScrollLeft = <T extends HTMLElement>(element: RefObject<T>): number => {
	if (element.current) {
		const { scrollLeft } = element.current;

		return scrollLeft;
	}

	return 0;
};

const getScrollRight = <T extends HTMLElement>(element: RefObject<T>): number => {
	if (element.current) {
		const { scrollWidth, offsetWidth, scrollLeft } = element.current;

		return scrollWidth - offsetWidth - scrollLeft;
	}

	return 0;
};

export const useScroll = <T extends HTMLElement>(
	elementRef: React.RefObject<T>,
	throttleTime = 0
): IScroll => {
	const [scrollTop, setScrollTop] = useState(getScrollTop(elementRef));
	const [scrollBottom, setScrollBottom] = useState(getScrollBottom(elementRef));
	const [scrollLeft, setScrollLeft] = useState(getScrollLeft(elementRef));
	const [scrollRight, setScrollRight] = useState(getScrollRight(elementRef));

	const throttledUpdatePosition = useMemo(
		() =>
			throttle(() => {
				setScrollTop(getScrollTop(elementRef));
				setScrollBottom(getScrollBottom(elementRef));
				setScrollLeft(getScrollLeft(elementRef));
				setScrollRight(getScrollRight(elementRef));
			}, throttleTime),
		[elementRef, throttleTime]
	);

	useEffect(() => {
		const scrollElement = elementRef.current;

		if (scrollElement) {
			scrollElement.addEventListener('scroll', throttledUpdatePosition);
		}

		return () => {
			if (scrollElement) {
				scrollElement.removeEventListener('scroll', throttledUpdatePosition);
			}
		};
	}, [elementRef, throttledUpdatePosition]);

	return { scrollTop, scrollBottom, scrollLeft, scrollRight };
};
