import { FunctionComponent } from '@kelvininc/types';
import { RecoilRoot } from 'recoil';

export const withRecoilRoot = <Props,>(
	Component: FunctionComponent<Props & JSX.IntrinsicAttributes>
): FunctionComponent<Props & JSX.IntrinsicAttributes> => {
	return function RecoilRootWrapper(
		componentProps: Props & JSX.IntrinsicAttributes
	): JSX.Element {
		return (
			<RecoilRoot>
				<Component {...componentProps} />
			</RecoilRoot>
		);
	};
};
