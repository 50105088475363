import { type CodeEditor as MonacoApi } from '@kelvininc/react-ui-components';
import { Ref } from 'react';

export type CodeTranslatorFn = (object: object) => string;
export type CodeParserFn = (code: string) => object;

export type ICodeLanguageTransformer = {
	parse: CodeParserFn;
	translate: CodeTranslatorFn;
};

export enum ECodeTranslatorEditorLanguage {
	YAML = 'yaml',
	JSON = 'json'
}

export interface ICodeTranslatorEditorChangeData {
	code: string;
	object: object;
	valid: boolean;
	hasChanges: boolean;
	language: ECodeTranslatorEditorLanguage;
}

export interface ICodeTranslatorEditorProps {
	object: object;
	language: ECodeTranslatorEditorLanguage;
	readOnly: boolean;
	onChange?: (data: ICodeTranslatorEditorChangeData) => void;
	monacoApiRef?: Ref<MonacoApi | null>;
	editorApiRef?: Ref<CodeEditorApi | null>;
}

export type CodeEditorApi = {
	resetValue: (value: object) => void;
};
