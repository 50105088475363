import { useEffect } from 'react';

const didClickOnElement = <T extends HTMLElement>(
	element: T | null,
	target: Node | null
): boolean => {
	if (element === null || target === null) {
		return false;
	}

	return element.contains(target);
};

export const useClickOutsideAlerter = <T extends HTMLElement>(
	elementRef: React.RefObject<T | undefined>,
	onClickOutsideCallback: (event: MouseEvent) => void
) => {
	useEffect(() => {
		function handleClick(this: Document, event: MouseEvent): void {
			if (
				elementRef &&
				elementRef.current &&
				!didClickOnElement(elementRef.current, event.target as Node)
			) {
				onClickOutsideCallback(event);
			}
		}

		// Bind the event listener
		document.addEventListener('mousedown', handleClick);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClick);
		};
	}, [elementRef, onClickOutsideCallback]);
};

export const useClickOutsideAlerters = <T extends HTMLElement>(
	elementRefs: React.RefObject<T | undefined>[],
	onClickOutsideCallback: (event: MouseEvent) => void
) => {
	useEffect(() => {
		function handleClick(this: Document, event: MouseEvent): void {
			if (
				elementRefs.some(
					(elementRef) =>
						elementRef &&
						elementRef.current &&
						didClickOnElement(elementRef.current, event.target as Node)
				)
			) {
				return;
			}
			onClickOutsideCallback(event);
		}

		// Bind the event listener
		document.addEventListener('mousedown', handleClick);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClick);
		};
	}, [elementRefs, onClickOutsideCallback]);
};

export const useClickInsideAlerter = <T extends HTMLElement>(
	elementRef: React.RefObject<T | undefined>,
	onClickInsideCallback: (event: MouseEvent) => void
) => {
	useEffect(() => {
		function handleClick(this: Document, event: MouseEvent): void {
			if (
				elementRef &&
				elementRef.current &&
				didClickOnElement(elementRef.current, event.target as Node)
			) {
				onClickInsideCallback(event);
			}
		}

		// Bind the event listener
		document.addEventListener('click', handleClick);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('click', handleClick);
		};
	}, [elementRef, onClickInsideCallback]);
};
