import { CSSProperties } from 'react';

export const NAVIGATION_ANIMATION_IN_MILLIS = 300;
export const NAVIGATION_EXPANDED_WIDTH = 257;
export const NAVIGATION_COLLAPSED_WIDTH = 56;
export const NAVIGATION_EXPANDED_MAX_WIDTH = 457;

export const NAVIGATION_RESIZE_ANIMATION_TIME_IN_MILLIS = 300;

export const DEFAULT_RESIZE_CONFIG = {
	top: false,
	bottom: false,
	left: false,
	topRight: false,
	bottomRight: false,
	bottomLeft: false,
	topLeft: false
};

export const RESIZABLE_DEFAULT_STYLES: CSSProperties = {
	position: 'absolute',
	transition: `width ${NAVIGATION_RESIZE_ANIMATION_TIME_IN_MILLIS}ms ease-in-out`
};

export const RIGHT_RESIZABLE_DEFAULT_STYLES: CSSProperties = {
	right: '-10px'
};
