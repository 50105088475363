import { EIconName, EOtherIconName, ETagState, ITagStatus } from '@kelvininc/react-ui-components';

import { ECardStatus } from './types';

const getCardTagState = (status: ECardStatus): ETagState =>
	({
		[ECardStatus.Success]: ETagState.Success,
		[ECardStatus.Error]: ETagState.Error,
		[ECardStatus.Unknown]: ETagState.Unknown,
		[ECardStatus.Warning]: ETagState.Warning
	})[status];

const getCardTagIconName = (status: ECardStatus): EIconName | EOtherIconName =>
	({
		[ECardStatus.Success]: EIconName.Success,
		[ECardStatus.Error]: EIconName.CloseCircle,
		[ECardStatus.Unknown]: EIconName.Error,
		[ECardStatus.Warning]: EIconName.Error
	})[status];

export const getCardStatus = (status: ECardStatus): ITagStatus => ({
	icon: getCardTagIconName(status),
	state: getCardTagState(status)
});
