import { useCallback } from 'react';
import { RecoilState, useRecoilState } from 'recoil';

import {
	getInstanceSetting,
	getSetting,
	removeUserSetting,
	setSetting
} from '../../services/settings';
import { IAsyncStoragePayload, useAsyncStorage } from '../useAsyncStorage';

import { ISettingStorageConfig } from './types';

export const useSetting = <T, M = T>(
	key: string,
	defaultValue: T,
	recoilState: RecoilState<T>,
	config: ISettingStorageConfig<T, M> = {}
): IAsyncStoragePayload<T> => {
	const [, setValue] = useRecoilState(recoilState);

	const storage = useAsyncStorage(key, defaultValue, recoilState, {
		...config,
		getStorageValue: getSetting,
		setStorageValue: setSetting
	});

	const resetValue = useCallback(
		(): Promise<T> =>
			getInstanceSetting(key, defaultValue, config)
				.catch(() => defaultValue)
				.then(async (newValue) => {
					try {
						await removeUserSetting(key);
					} catch (error) {
						// ignore
					}

					setValue(newValue);
					return newValue;
				}),
		[config, defaultValue, key, setValue]
	);

	return {
		...storage,
		resetValue
	};
};
