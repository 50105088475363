import {
	buildDropdownGroupedOptionsFromArray,
	buildDropdownOptionsFromArray,
	searchDropdownOptions
} from '@kelvininc/tsutils';
import {
	IUIDropdownOptions,
	IUseDropdownSearchOptions,
	UseDropdownGroupedOptions,
	UseDropdownOptions
} from '@kelvininc/types';
import { useMemo } from 'react';

import { useSearchState } from '../useSearchState';

export const useDropdownSearch = (
	options: IUIDropdownOptions,
	initialSearch?: string,
	debounceTime?: number
): IUseDropdownSearchOptions => {
	const [searchTerm, setSearchTerm] = useSearchState(initialSearch, debounceTime);

	const filteredOptions = useMemo(() => {
		if (searchTerm && searchTerm.length > 0) {
			return searchDropdownOptions(searchTerm, options);
		}

		return options;
	}, [options, searchTerm]);

	return useMemo(
		() => ({
			options,
			searchTerm,
			setSearchTerm,
			filteredOptions
		}),
		[filteredOptions, options, searchTerm, setSearchTerm]
	);
};

export const useDropdownSearchOptions = <T>(
	data: T[],
	options: UseDropdownOptions<T>,
	initialSearch?: string,
	debounceTime?: number
): IUseDropdownSearchOptions => {
	return useDropdownSearch(useDropdownOptions(data, options), initialSearch, debounceTime);
};

export const useDropdownOptions = <T>(
	data: T[],
	options: UseDropdownOptions<T>
): IUIDropdownOptions => {
	return useMemo(() => buildDropdownOptionsFromArray({ data, ...options }), [data, options]);
};

export const useDropdownSearchGroupedOptions = <T>(
	data: T[],
	options: UseDropdownGroupedOptions<T>,
	initialSearch?: string,
	debounceTime?: number
): IUseDropdownSearchOptions => {
	return useDropdownSearch(useDropdownGroupedOptions(data, options), initialSearch, debounceTime);
};

export const useDropdownGroupedOptions = <T>(
	data: T[],
	options: UseDropdownGroupedOptions<T>
): IUIDropdownOptions => {
	return useMemo(
		() => buildDropdownGroupedOptionsFromArray({ data, ...options }),
		[data, options]
	);
};
