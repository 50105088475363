import { InstanceService } from '@kelvininc/node-client-sdk';
import { catchHttpError, throwHttpError } from '@kelvininc/tsutils';
import { identity, isNil } from 'lodash-es';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { IGetSettingConfig, ISetSettingConfig } from './types';

export const getInstanceSetting = async <T, M = T>(
	key: string,
	defaultValue: T,
	{ deserializer = identity, validator = () => true }: IGetSettingConfig<T, M> = {}
): Promise<T> => {
	return InstanceService.getInstanceSettings({ settingName: key })
		.pipe(
			map(({ payload }) => {
				if (!isNil(payload)) {
					const setting = deserializer(payload);
					if (validator(setting)) {
						return setting;
					}
				}

				return defaultValue;
			}),
			catchError((error) => {
				console.warn(error);
				return of(defaultValue);
			})
		)
		.toPromise();
};

export const setInstanceSetting = async <T, M = T>(
	key: string,
	value: T,
	{ serializer = identity }: ISetSettingConfig<T, M> = {}
): Promise<void> => {
	if (isNil(value)) {
		return removeInstanceSetting(key);
	}

	await InstanceService.updateInstanceSettings({
		settingName: key,
		instanceSettingsUpdateData: { payload: serializer(value) }
	})
		.pipe(catchHttpError(throwHttpError))
		.toPromise();
};

export const removeInstanceSetting = async (key: string): Promise<void> => {
	await InstanceService.deleteInstanceSetting({
		settingName: key
	})
		.pipe(catchHttpError(throwHttpError))
		.toPromise();
};
