import {
	EIconName,
	EOtherIconName,
	ISelectOptionWithChildren
} from '@kelvininc/react-ui-components';
import { EChartAxisType, IActivityAxis } from '@kelvininc/shared-ui';
import { ICoreAppPermissions } from '@kelvininc/types';
import { ReactNode } from 'react';

export enum ETimeseriesRangeGetDataFill {
	None = 'none',
	Linear = 'linear',
	Previous = 'previous',
	FixedIntegerValue = 'fixed integer value'
}

export enum EDataExplorerQueryParams {
	Scope = 'scope',
	ScopeName = 'scope-name',
	Assets = 'assets',
	Highlight = 'highlight',
	Origin = 'origin'
}

export enum EDataExplorerOrigin {
	Menu = 'menu',
	Asset = 'asset'
}

export enum EDataSourceScope {
	All = 'all-assets',
	AssetType = 'asset-type',
	Applications = 'applications'
}

export enum EChartLayout {
	List = 'list',
	AllInOne = 'allInOne'
}

export enum ESidepanelTab {
	DataSource = 'data-source',
	Activity = 'activity'
}

export interface ISidepanelTabConfig {
	key: ESidepanelTab;
	icon: EIconName | EOtherIconName;
	tooltip: string;
	disabled?: boolean;
}

export interface IActivityLine extends IActivityAxis {
	id: string;
	title: string;
	active: boolean;
	disabled?: boolean;
}

export enum ESidepanelState {
	Open = 'open',
	Closed = 'closed'
}

export interface IHighlightPoint {
	id: string;
	axis: EChartAxisType;
}

export interface IDatastreamDataExplorerSetting {
	color: string;
	hidden?: boolean;
}

export interface IHighlightPointTime extends IHighlightPoint {
	timestamp: number;
}

export interface IAssetDatastreamsSelectOption extends ISelectOptionWithChildren {
	searchableBy: string;
	assetName: string;
	semanticType: string;
}

export type DataExplorerProps = {
	activities?: IActivityLine[];
	topbar?: ReactNode;
	permissions?: ICoreAppPermissions;
};

export interface IAssetDataExplorerQueryParams {
	[EDataExplorerQueryParams.Scope]?: EDataSourceScope;
	[EDataExplorerQueryParams.ScopeName]?: string;
	[EDataExplorerQueryParams.Assets]?: string[];
	[EDataExplorerQueryParams.Highlight]?: IHighlightPointTime[];
	[EDataExplorerQueryParams.Origin]?: EDataExplorerOrigin;
}

export type SelectedAssetDatastreamsMap = Record<string, Record<string, boolean>>;
