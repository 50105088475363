import { Transition, Variants } from 'framer-motion';

import { EEntranceAnimation, IPanelAnimationVariantsCustom } from './types';

const DEFAULT_PANEL_WIDTH = 300;
const SLIDE_ANIMATION_DURATION_IN_SECS = 0.3;

const ENTRANCE_ANIMATION_FACTOR: Record<EEntranceAnimation, number> = {
	[EEntranceAnimation.SlideLeft]: 1,
	[EEntranceAnimation.SlideRight]: -1
};

export const PANEL_SLIDE_ANIMATION_VARIANTS: Variants = {
	enter: ({ animation, width = DEFAULT_PANEL_WIDTH }: IPanelAnimationVariantsCustom) => ({
		x: ENTRANCE_ANIMATION_FACTOR[animation] * width,
		transition: {
			duration: SLIDE_ANIMATION_DURATION_IN_SECS
		},
		opacity: 0
	}),
	slide: {
		x: 0,
		transition: {
			duration: SLIDE_ANIMATION_DURATION_IN_SECS
		},
		opacity: 1
	},
	exit: ({ animation, width = DEFAULT_PANEL_WIDTH }: IPanelAnimationVariantsCustom) => ({
		x: ENTRANCE_ANIMATION_FACTOR[animation] * width,
		transition: {
			duration: SLIDE_ANIMATION_DURATION_IN_SECS
		},
		opacity: 0
	})
};

export const PANEL_SLIDE_ANIMATION_TRANSITION_CONFIG: Transition = { ease: 'easeInOut' };
