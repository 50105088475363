import { AnimatePresence, motion } from 'framer-motion';
import { PropsWithChildren } from 'react';

import { usePanelsManager } from '../..';

import { usePanelsContext } from '../../contexts';

import { PANEL_SLIDE_ANIMATION_TRANSITION_CONFIG, PANEL_SLIDE_ANIMATION_VARIANTS } from './config';
import styles from './styles.module.scss';
import { getPanelAnimationInitialState, getPanelEntranceAnimation } from './utils';

type PanelProps = {
	id: string;
	width?: number;
};

export const PanelManager = ({ id, width, children }: PropsWithChildren<PanelProps>) => {
	const { panels, history } = usePanelsContext();
	const { panel: currentPanel } = usePanelsManager();

	const isVisible = id === currentPanel;
	const animation = getPanelEntranceAnimation(currentPanel, history, panels);
	const initial = getPanelAnimationInitialState(history);

	return (
		<AnimatePresence initial={initial !== false}>
			{isVisible && (
				<motion.div
					key={id}
					variants={PANEL_SLIDE_ANIMATION_VARIANTS}
					custom={{ animation, width }}
					className={styles.PanelManager}
					initial={initial}
					animate="slide"
					exit="exit"
					transition={PANEL_SLIDE_ANIMATION_TRANSITION_CONFIG}>
					{children}
				</motion.div>
			)}
		</AnimatePresence>
	);
};
