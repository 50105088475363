import { EPaginatorType, UserItem, UserMeGet, UserService } from '@kelvininc/node-client-sdk';

import { catchHttpError, throwHttpError } from '@kelvininc/tsutils';

export const getUserMe = (): Promise<UserMeGet> => {
	return UserService.getUserMe().pipe(catchHttpError(throwHttpError)).toPromise();
};

export const getUserList = (): Promise<UserItem[]> => {
	return UserService.listUsers({ paginationType: EPaginatorType.Stream })
		.pipe(catchHttpError(throwHttpError))
		.toPromise();
};
