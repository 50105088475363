import { AnimationProps } from 'framer-motion';

import { EEntranceAnimation } from './types';

export const getPanelEntranceAnimation = (
	currentPanel: string,
	history: string[],
	panels: string[]
): EEntranceAnimation => {
	if (history.length < 2) {
		return EEntranceAnimation.SlideRight;
	}

	const previousPanel = history[history.length - 2];
	const currentPanelIndex = panels.indexOf(currentPanel);
	const previousPanelIndex = panels.indexOf(previousPanel);

	if (currentPanelIndex > previousPanelIndex) {
		return EEntranceAnimation.SlideLeft;
	}

	return EEntranceAnimation.SlideRight;
};

export const getPanelAnimationInitialState = (history: string[]): AnimationProps['initial'] => {
	return history.length > 1 ? 'enter' : false;
};
