import { EControlChangeState } from '@kelvininc/node-client-sdk';
import { ITagStatus, KvTagStatus } from '@kelvininc/react-ui-components';
import { getControlChangeTagStatus } from '@kelvininc/tsutils';
import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';

type ControlChangeStatusProps = {
	status: EControlChangeState;
	customClasses?: ClassNamesProp;
} & Omit<ITagStatus, 'state' | 'icon' | 'label'>;

export const ControlChangeStatus = ({
	status,
	customClasses,
	...otherProps
}: ControlChangeStatusProps) => {
	return (
		<KvTagStatus
			class={classNames(customClasses)}
			{...getControlChangeTagStatus(status)}
			{...otherProps}
		/>
	);
};
