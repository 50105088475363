import { debounce, isFunction } from 'lodash-es';
import { SetStateAction, useCallback, useMemo } from 'react';

import { RecoilState, useRecoilCallback, useRecoilState } from 'recoil';

import { IAsyncStorageConfig, IAsyncStoragePayload } from './types';
import { areValuesEqual } from './utils';

export const useAsyncStorage = <T, M = T>(
	key: string,
	defaultValue: T,
	recoilState: RecoilState<T>,
	{
		serializer,
		deserializer,
		getStorageValue,
		setStorageValue,
		validator,
		comparator,
		debounceTime
	}: IAsyncStorageConfig<T, M>
): IAsyncStoragePayload<T> => {
	const [value] = useRecoilState(recoilState);

	const debouncer = useMemo(() => {
		if (!debounceTime) {
			return (callback: (params: void) => void) => callback();
		}

		return debounce((callback: (params: void) => void) => callback(), debounceTime);
	}, [debounceTime]);

	const getValue = useCallback(
		() => getStorageValue(key, defaultValue, { deserializer, validator }),
		[getStorageValue, key, defaultValue, deserializer, validator]
	);

	const setValue = useRecoilCallback(
		({ set: setStateValue, snapshot }) =>
			async (stateAction: SetStateAction<T>, persist = true) => {
				const previousValue = await snapshot.getPromise(recoilState);

				return new Promise<void>((resolve, reject) => {
					const newValue: T = isFunction(stateAction)
						? stateAction(previousValue)
						: stateAction;

					if (
						comparator?.(previousValue, newValue) ??
						areValuesEqual(previousValue, newValue, serializer)
					) {
						resolve();
						return;
					}

					setStateValue(recoilState, newValue);

					if (persist) {
						debouncer(() =>
							setStorageValue(key, newValue, { serializer })
								.then(() => resolve())
								.catch(() => {
									setStateValue(recoilState, previousValue);
									reject();
								})
						);
					}
				});
			},
		[comparator, debouncer, key, recoilState, serializer, setStorageValue]
	);

	const resetValue = useCallback(() => {
		setValue(defaultValue);
		return Promise.resolve(defaultValue);
	}, [defaultValue, setValue]);

	return { value, getValue, setValue, resetValue };
};
