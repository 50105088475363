import {
	DEFAULT_RESIZE_CONFIG,
	NAVIGATION_COLLAPSED_WIDTH,
	NAVIGATION_EXPANDED_WIDTH
} from './config';

export const getResizeConfig = (right = false) => ({ ...DEFAULT_RESIZE_CONFIG, right });

export const getSideBarWidth = (newFixed: boolean): number =>
	newFixed ? NAVIGATION_EXPANDED_WIDTH : NAVIGATION_COLLAPSED_WIDTH;
