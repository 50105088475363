import ReactCollapse from '@kunukn/react-collapse';
import { PropsWithChildren } from 'react';

import './styles.scss';

type Collapse = {
	isOpen: boolean;
	overflowOnExpanded?: boolean;
};

export const Collapse = ({
	isOpen,
	overflowOnExpanded = false,
	children
}: PropsWithChildren<Collapse>) => {
	return (
		<ReactCollapse isOpen={isOpen} overflowOnExpanded={overflowOnExpanded}>
			{children}
		</ReactCollapse>
	);
};
