import { EActionButtonType } from '@kelvininc/react-ui-components';
import { HTMLProps } from 'react';

export const DEFAULT_CONFIRM_LABEL = 'Confirm';
export const DEFAULT_CANCEL_LABEL = 'Cancel';

export const DEFAULT_CONFIRM_CONFIG: HTMLProps<HTMLKvActionButtonElement> = {
	type: EActionButtonType.Primary
};
export const DEFAULT_CANCEL_CONFIG: HTMLProps<HTMLKvActionButtonElement> = {
	type: EActionButtonType.Secondary
};
