import { ClassNamesProp, PropsWithForwardedRef } from '@kelvininc/types';
import classNames from 'classnames';
import { ForwardedRef, HTMLAttributes, PropsWithChildren, forwardRef } from 'react';
import SimpleBarCore from 'simplebar-core';
import SimpleBar from 'simplebar-react';

import './styles.scss';

type ScrollableProps = {
	customClasses?: ClassNamesProp;
	scrollableNodeProps?: object;
	scrollableNodeRef?: React.MutableRefObject<SimpleBarCore>;
	contentNodeRef?: React.MutableRefObject<SimpleBarCore>;
} & HTMLAttributes<HTMLDivElement>;

export const Scrollable = ({
	customClasses,
	children,
	forwardedRef,
	...otherProps
}: PropsWithForwardedRef<PropsWithChildren<ScrollableProps>, SimpleBarCore>) => {
	return (
		<SimpleBar
			ref={forwardedRef}
			className={classNames('scrollable', customClasses)}
			{...otherProps}>
			{children}
		</SimpleBar>
	);
};

Scrollable.Forward = forwardRef(function ScrollableWithRef(
	props: PropsWithChildren<ScrollableProps>,
	ref: ForwardedRef<SimpleBarCore>
) {
	return <Scrollable {...props} forwardedRef={ref} />;
});
