import {
	EActionButtonType,
	EIconName,
	ETooltipPosition,
	KvActionButtonIcon,
	KvTagStatus,
	KvTooltip
} from '@kelvininc/react-ui-components';
import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';

import { isEmpty } from 'lodash-es';
import { PropsWithChildren } from 'react';

import styles from './styles.module.scss';
import { ECardStatus } from './types';

import { getCardStatus } from './utils';

type CardProps = {
	title?: string;
	description?: string;
	status?: ECardStatus;
	statusTooltip?: string;
	customClasses?: ClassNamesProp;
} & CardPinProps;

export type CardPinProps = {
	allowPin?: boolean;
	disabledPin?: boolean;
	pinned?: boolean;
	onTogglePin?: () => void;
};

export const CardHeader = ({ customClasses, children }: PropsWithChildren<CardProps>) => {
	return <div className={classNames(styles.CardHeader, customClasses)}>{children}</div>;
};

const CardPin = ({ allowPin, pinned, disabledPin = false, onTogglePin }: CardPinProps) => {
	if (!allowPin) {
		return null;
	}
	return (
		<KvActionButtonIcon
			className={classNames(styles.Pin, {
				[styles.PinActive]: pinned
			})}
			type={EActionButtonType.Ghost}
			onClick={(e) => {
				e.stopPropagation();
				e.preventDefault();
			}}
			onClickButton={onTogglePin}
			disabled={disabledPin}
			icon={EIconName.PushPin}
		/>
	);
};

export const CardBody = (props: PropsWithChildren<CardProps>) => {
	const { customClasses, title, description, children } = props;
	const hasInfo = !isEmpty(title) || !isEmpty(description);
	return (
		<div className={classNames(styles.CardBody, customClasses)}>
			{hasInfo && (
				<div className={styles.Info}>
					<div className={styles.Header}>
						<CardPin {...props} />
						{!isEmpty(title) && (
							<KvTooltip text={title} truncate>
								<div className={styles.Title}>{title}</div>
							</KvTooltip>
						)}
					</div>
					{!isEmpty(description) && (
						<div className={styles.Description}>{description}</div>
					)}
				</div>
			)}
			<>{children}</>
		</div>
	);
};

export const CardFooter = ({ customClasses, children }: PropsWithChildren<CardProps>) => {
	return <div className={classNames(customClasses, styles.CardFooter)}>{children}</div>;
};

export const Card = ({
	status,
	customClasses,
	children,
	statusTooltip
}: PropsWithChildren<CardProps>) => {
	return (
		<div
			className={classNames(styles.Card, customClasses, {
				[styles.CardSuccess]: status === ECardStatus.Success,
				[styles.CardError]: status === ECardStatus.Error,
				[styles.CardUnknown]: status === ECardStatus.Unknown,
				[styles.CardWarning]: status === ECardStatus.Warning
			})}>
			{status && (
				<div className={styles.StatusIcon}>
					<KvTooltip
						text={statusTooltip}
						position={ETooltipPosition.Top}
						customClass={styles.Tooltip}>
						<KvTagStatus {...getCardStatus(status)} />
					</KvTooltip>
				</div>
			)}
			{children}
		</div>
	);
};
