import { identity, isEqual } from 'lodash-es';

import { ISettingSerializer } from '../../services';

export const areValuesEqual = <T, M = T>(
	oldValue: T,
	newValue: T,
	serializer: ISettingSerializer<T, M> = identity
): boolean => {
	const serializedOldValue = serializer(oldValue);
	const serializedNewValue = serializer(newValue);

	return isEqual(serializedOldValue, serializedNewValue);
};
