import { ErrorBoundary } from '@sentry/nextjs';
import { PropsWithChildren, Suspense } from 'react';

import { ErrorFallback } from '../ErrorFallback';
import { SuspenseFallback } from '../SuspenseFallback';

import { BoundariesProps } from './types';

export const Boundaries = ({
	ErrorFallbackComponent = <ErrorFallback />,
	SuspenseFallbackComponent = <SuspenseFallback />,
	children
}: PropsWithChildren<BoundariesProps>) => {
	return (
		<ErrorBoundary fallback={ErrorFallbackComponent}>
			<Suspense fallback={SuspenseFallbackComponent}>{children}</Suspense>
		</ErrorBoundary>
	);
};
