import { ComputePositionConfig } from '@floating-ui/dom';
import { KvActionButton, KvIcon, KvPortal, KvTooltip } from '@kelvininc/react-ui-components';
import { ClassNamesProp, FunctionComponent } from '@kelvininc/types';
import classNames from 'classnames';
import { uniqueId } from 'lodash-es';
import {
	Context,
	PropsWithChildren,
	ReactElement,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react';

import { useClickOutsideAlerters, useWindowSize } from '../../hooks';
import { Link } from '../Link';

import { DEFAULT_DROPDOWN_Z_INDEX } from './config';
import styles from './styles.module.scss';
import { EDropdownSize, IDropdownContextValues, IDropdownItem } from './types';

const DropdownContext: Context<null | IDropdownContextValues> =
	createContext<null | IDropdownContextValues>(null);

type DropdownProps = {
	actionRef?: React.RefObject<HTMLDivElement>;
	options?: Partial<ComputePositionConfig>;
	items?: IDropdownItem[];
	customClasses?: ClassNamesProp;
	size?: EDropdownSize;
	header?: ReactElement;
	footer?: ReactElement;
	selectedItem?: string;
	onClickItem?: (item: string) => void;
};

export const Dropdown = ({
	actionRef,
	options,
	items,
	customClasses,
	size = EDropdownSize.Small,
	header,
	footer,
	onClickItem,
	selectedItem,
	children
}: PropsWithChildren<DropdownProps>) => {
	const { isOpen, close } = useDropdown();
	const listRef = useRef<HTMLDivElement>(null);
	const windowSize = useWindowSize();
	const actionChildrenRef = useRef<HTMLDivElement>(null);
	const portalId = useMemo(() => `dropdown-${uniqueId()}`, []);

	const onClickOutside = useCallback(() => {
		if (isOpen) {
			close();
		}
	}, [close, isOpen]);

	const refs = useMemo(() => [actionChildrenRef ?? actionRef, listRef], [actionRef]);
	useClickOutsideAlerters(refs, onClickOutside);

	useEffect(() => {
		return () => {
			if (isOpen) {
				document.getElementById(portalId)?.remove();
			}
		};
	}, [isOpen, portalId]);

	useEffect(() => {
		close();
	}, [close, windowSize]);

	const onClickDropdownItem = useCallback(
		(item: IDropdownItem) => {
			item.onClick?.();
			onClickItem?.(item.id);
			close();
		},
		[close, onClickItem]
	);

	return (
		<div className={classNames(customClasses)}>
			{children && <div ref={actionChildrenRef}>{children}</div>}
			{isOpen && (
				<div>
					<KvPortal
						portalId={portalId}
						animated
						show
						reference={actionChildrenRef?.current ?? actionRef?.current ?? undefined}
						options={options}
						zIndex={DEFAULT_DROPDOWN_Z_INDEX}>
						<div
							className={classNames(styles.DropdownMenu, { [styles[size]]: true })}
							ref={listRef}>
							{header}
							<div className={styles.DropdownLinks}>
								{items?.map((item) => (
									<KvTooltip
										key={item.id}
										text={item.tooltip}
										position={item.tooltipPosition}>
										<Link
											href={item.url}
											target={item.target}
											prefetch={item.prefetch}
											rel="noreferrer"
											disabled={item.disabled}>
											<KvActionButton
												className={classNames(styles.DropdownItem, {
													[styles.Disabled]: item.disabled,
													[styles.Selected]: item.id === selectedItem
												})}
												onClickButton={() => onClickDropdownItem(item)}>
												<div
													className={styles.DropdownText}
													style={
														{
															'--dropdown-level': `${item.itemLevel}`
														} as React.CSSProperties
													}>
													{item.leftIcon && (
														<KvIcon name={item.leftIcon} />
													)}
													<span>{item.text}</span>
												</div>
												{item.rightIcon && <KvIcon name={item.rightIcon} />}
											</KvActionButton>
										</Link>
									</KvTooltip>
								))}
							</div>
							{footer}
						</div>
					</KvPortal>
				</div>
			)}
		</div>
	);
};

export const DropdownProvider = <T,>({ children }: PropsWithChildren<T>) => {
	const [isOpen, setOpen] = useState(false);

	const open = useCallback(() => setOpen(true), []);
	const close = useCallback(() => setOpen(false), []);
	const toggle = () => (isOpen ? close() : open());

	return (
		<DropdownContext.Provider value={{ isOpen, toggle, open, close, setOpen }}>
			{children}
		</DropdownContext.Provider>
	);
};

export const useDropdown = (): IDropdownContextValues => {
	const context = useContext(DropdownContext);

	if (!context) {
		throw new Error('Missing dropdown context');
	}

	return context;
};

export const withDropdownProvider = <ComponentProps,>(
	Component: FunctionComponent<ComponentProps>
) => {
	return function DropdownProviderWrapper(componentProps: PropsWithChildren<ComponentProps>) {
		return (
			<DropdownProvider>
				<Component {...componentProps} />
			</DropdownProvider>
		);
	};
};
