import { PropsWithChildren, useMemo } from 'react';

type TabPanelProps<T extends string = string> = {
	tab: T;
	activeTab: T;
};

export const TabPanel = <T extends string = string>({
	tab,
	activeTab,
	children
}: PropsWithChildren<TabPanelProps<T>>) => {
	const isActive = useMemo(() => activeTab === tab, [activeTab, tab]);

	if (!isActive) {
		return null;
	}

	return <>{children}</>;
};
