import {
	CODE_TRANSFORMERS,
	NO_PARSER_FOUND_EXCEPTION,
	NO_TRANSLATOR_FOUND_EXCEPTION
} from './config';
import { ECodeTranslatorEditorLanguage } from './types';

export const translateCode = (
	object: object,
	targetLanguage: ECodeTranslatorEditorLanguage
): string => {
	const transformer = CODE_TRANSFORMERS[targetLanguage];

	if (!transformer) {
		throw new Error(NO_TRANSLATOR_FOUND_EXCEPTION);
	}

	return transformer.translate(object);
};

export const parseCode = (code: string, activeLanguage: ECodeTranslatorEditorLanguage): object => {
	const transformer = CODE_TRANSFORMERS[activeLanguage];

	if (!transformer) {
		throw new Error(NO_PARSER_FOUND_EXCEPTION);
	}

	return transformer.parse(code);
};
