import { EActionButtonType, KvActionButtonText } from '@kelvininc/react-ui-components';
import { merge } from 'lodash-es';
import { ComponentProps } from 'react';

import {
	DEFAULT_CANCEL_CONFIG,
	DEFAULT_CANCEL_LABEL,
	DEFAULT_CONFIRM_CONFIG,
	DEFAULT_CONFIRM_LABEL
} from './config';

export type AlertActionsProps = {
	size?: ComponentProps<typeof KvActionButtonText>['size'];
	confirmLabel?: string;
	onClickConfirm: () => void;
	confirmButtonConfig?: Partial<ComponentProps<typeof KvActionButtonText>>;
	cancelLabel?: string;
	onClickCancel: () => void;
	cancelButtonConfig?: Partial<ComponentProps<typeof KvActionButtonText>>;
};

export const AlertActions = ({
	size,
	confirmLabel = DEFAULT_CONFIRM_LABEL,
	onClickConfirm,
	confirmButtonConfig,
	cancelLabel = DEFAULT_CANCEL_LABEL,
	onClickCancel,
	cancelButtonConfig
}: AlertActionsProps) => (
	<>
		<KvActionButtonText
			text={cancelLabel}
			type={EActionButtonType.Secondary}
			onClickButton={onClickCancel}
			size={size}
			{...merge({}, DEFAULT_CANCEL_CONFIG, cancelButtonConfig)}
		/>
		<KvActionButtonText
			text={confirmLabel}
			type={EActionButtonType.Primary}
			onClickButton={onClickConfirm}
			size={size}
			{...merge({}, DEFAULT_CONFIRM_CONFIG, confirmButtonConfig)}
		/>
	</>
);
