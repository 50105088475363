import { PropsWithChildren, createContext, useContext, useState } from 'react';

import { PanelsContextValues } from './types';

export const PanelsContext = createContext<PanelsContextValues | null>(null);

type PanelsContextProviderProps = {
	initialPanel: string;
	panels: string[];
};

export const PanelsContextProvider = ({
	initialPanel,
	panels,
	children
}: PropsWithChildren<PanelsContextProviderProps>) => {
	const [history, setHistory] = useState<string[]>([initialPanel]);

	return (
		<PanelsContext.Provider value={{ history, setHistory, panels }}>
			{children}
		</PanelsContext.Provider>
	);
};

export const usePanelsContext = () => {
	const context = useContext(PanelsContext);

	if (!context) {
		throw new Error('usePanelsContext must be used within a PanelsContextProvider');
	}

	return context;
};
