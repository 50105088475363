import { AtomEffect } from 'recoil';

import { IGetSettingConfig, getSetting } from '../../services/settings';

export const syncSettingInitialValue: <T, M = T>(
	settingKey: string,
	defaultValue: T,
	config?: IGetSettingConfig<T, M>
) => AtomEffect<T> =
	(settingKey, defaultValue, config) =>
	({ setSelf }) =>
		setSelf(getSetting(settingKey, defaultValue, config));
