import { FunctionComponent } from '@kelvininc/types';
import classNames from 'classnames';
import {
	CSSProperties,
	Context,
	PropsWithChildren,
	createContext,
	createRef,
	useCallback,
	useContext,
	useMemo,
	useState
} from 'react';
import { createPortal } from 'react-dom';

import { Overlay } from '../Overlay';
import { Panel, PanelProps } from '../Panel';

import { SIDEPANEL_DEFAULT_KEY } from './config';
import styles from './styles.module.scss';
import { ISidepanelContextValues } from './types';

const SidepanelContext: Context<null | ISidepanelContextValues> =
	createContext<null | ISidepanelContextValues>(null);

type SidepanelProps = {
	name?: string;
	closable?: boolean;
	useOverlay?: boolean;
	onClickOverlay?: () => void;
	onCloseSidepanel?: () => void;
	style?: CSSProperties;
} & PanelProps;

export const Sidepanel = ({
	name = SIDEPANEL_DEFAULT_KEY,
	customClasses,
	useOverlay = false,
	onClickOverlay,
	onCloseSidepanel,
	children,
	style,
	...otherProps
}: PropsWithChildren<SidepanelProps>) => {
	const { isOpen, toggle, ref } = useSidepanel();

	const overlayEnabled = useMemo(() => isOpen(name) && useOverlay, [isOpen, name, useOverlay]);

	const closeSidepanel = useCallback(() => {
		toggle(name);
		onCloseSidepanel?.();
	}, [name, toggle, onCloseSidepanel]);

	return createPortal(
		<>
			<Overlay open={overlayEnabled} onClick={onClickOverlay ?? closeSidepanel} />
			<div
				data-test-id="e2e-sidepanel"
				className={classNames(styles.Sidepanel, customClasses, {
					[styles.Open]: isOpen(name)
				})}
				style={style}
				ref={ref}>
				<Panel onRequestClose={closeSidepanel} {...otherProps}>
					{children}
				</Panel>
			</div>
		</>,
		document.getElementById('sidepanel-root') as Element
	);
};

export const SidepanelProvider = ({ children }: PropsWithChildren<{}>) => {
	const [openState, setOpenState] = useState<Record<string, boolean>>({});
	const ref = createRef<HTMLDivElement>();

	const open = useCallback(
		(key: string) =>
			setOpenState((previousOpenState) => ({
				...previousOpenState,
				[key]: true
			})),
		[]
	);

	const close = useCallback(
		(key: string) =>
			setOpenState((previousOpenState) => ({
				...previousOpenState,
				[key]: false
			})),
		[]
	);

	const toggle = useCallback(
		(key: string) => (openState[key] ? close(key) : open(key)),
		[close, open, openState]
	);
	const isOpen = useCallback((key: string) => openState[key] === true, [openState]);

	return (
		<SidepanelContext.Provider
			value={{
				isOpen,
				ref,
				open,
				close,
				toggle
			}}>
			{children}
		</SidepanelContext.Provider>
	);
};

export const SidepanelConsumer = SidepanelContext.Consumer;

export function useSidepanel(): ISidepanelContextValues {
	const context = useContext(SidepanelContext);

	if (!context) {
		throw new Error('Missing sidepanel context');
	}

	return context;
}

export const withSidepanel = <ComponentProps,>(
	Component: FunctionComponent<ComponentProps>,
	props?: SidepanelProps
) => {
	return function SidepanelWrapper(componentProps: PropsWithChildren<ComponentProps>) {
		return (
			<Sidepanel {...props}>
				<Component {...componentProps} />
			</Sidepanel>
		);
	};
};

export const withSidepanelProvider = <ComponentProps,>(
	Component: FunctionComponent<ComponentProps>
) => {
	return function SidepanelProviderWrapper(componentProps: PropsWithChildren<ComponentProps>) {
		return (
			<SidepanelProvider>
				<Component {...componentProps} />
			</SidepanelProvider>
		);
	};
};
